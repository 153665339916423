import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Main from "../../components/Layout/Main";
import Helmet from 'react-helmet';
import CoverImage from '../../components/CoverImage';
import TextBlock from '../../components/TextBlock/TextBlock';
import PressQuotes from '../../components/PressQuotes/PressQuotes';
import LargeEditorial from '../../components/LargeEditorial';

const Overview = () => {
  const { markdownRemark } = useStaticQuery(graphql`
    query OverviewQuery {
      markdownRemark(frontmatter: { pageId: { eq: "overview-page" } }) {
        frontmatter {
          components {
            type
            id
            deeplink
            variant
            content {
              video {
                src
                loop
                autoplay
                controls
              }
              copy {
                leader
                trailer
                caption
                quotes {
                  line
                  source
                }
                headlines {
                  line
                }
                subheads {
                  line
                }
                body {
                  line
                }
              }
              image {
                childImageSharp {
                  fluid(maxWidth: 2048, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
                extension
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  const availComponents = {
    CoverImage,
    TextBlock,
    PressQuotes,
    LargeEditorial
  }

  const renderComponents = () => {
    const components = markdownRemark.frontmatter.components;
    let hydratedComponents = [];
    components.forEach((component, i) => {
      const ComponentRef = availComponents[component.type];
      const deeplinkId = component.deeplink ? component.id : '';
      hydratedComponents.push(<ComponentRef key={i} id={deeplinkId} variant={component.variant} {...component.content}/>)
    })
    return hydratedComponents
  }

  return (
    <Main>
      <Helmet>
        <body className="page overview-page" />
      </Helmet>
      {renderComponents()}
    </Main>
  );
}

export default Overview
